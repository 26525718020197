<template>
  <div class="d-flex flex-column flex-grow-1">
    <template v-for="(category, i) in categories">
      <div
        v-if="category.articles && category.articles.length"
        :key="category.id"
        :class="{ 'mt-5': i > 0 }"
        class="category-title mb-2 d-flex align-center justify-space-between"
        :style="{
          'border-color': category.color
            ? category.color + '!important'
            : 'var(--v-primary-base)',
        }"
      >
        <h1
          :style="{
            'background-color': category.color
              ? category.color + '!important'
              : 'var(--v-primary-base)',
          }"
          class="title d-inline-block py-2 px-5 primary white--text"
        >
          {{ category.name }}
        </h1>
        <v-btn
          v-if="!category.hide_details"
          text
          :to="localePath(`/categories/${category.id}/view/${category.slug}`)"
          >Vezi mai multe</v-btn
        >
        <edit-asset-button
          :link="`/categories/edit/${category.slug}`"
        ></edit-asset-button>
      </div>
      <v-row
        v-if="category.articles && category.articles.length"
        :key="category.name"
      >
        <v-col>
          <component
            :is="getComponentName(category)"
            :data="category"
          ></component>
        </v-col>
      </v-row>
    </template>
    <component
      :is="getWidgetComponentName(widget.type)"
      v-for="widget in pageWidgets"
      :key="widget.id"
      class="mt-5"
      :data="widget"
      with-edit
    ></component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Layout1 from '~/components/category/Layout1'
import Layout2 from '~/components/category/Layout2'
import Layout3 from '~/components/category/Layout3'
import EditAssetButton from '~/components/common/EditAssetButton'
import NewsletterWidget from '~/components/widget/NewsletterWidget'

export default {
  name: 'HomePage',
  components: {
    EditAssetButton,
    Layout1,
    Layout2,
    Layout3,
    NewsletterWidget,
  },
  layout: 'client',
  fetch() {
    return this.getData('home-articles')
  },
  head() {
    return {
      title: 'Acasă',
    }
  },
  computed: {
    ...mapState('homeArticles', ['categories']),
    ...mapState('homeWidgets', ['pageWidgets']),
  },
  methods: {
    ...mapActions('resources', ['getData']),
    getComponentName(category) {
      switch (category.layout) {
        case 1:
          return 'Layout1'
        case 2:
          return 'Layout2'
        case 3:
          return 'Layout3'
      }
    },
    getWidgetComponentName(type) {
      switch (type) {
        case 'newsletter':
          return 'NewsletterWidget'
      }
    },
  },
}
</script>
