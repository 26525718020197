<template>
  <LazyHydrate when-visible>
    <div v-if="category.articles && category.articles.length">
      <v-row dense>
        <template v-for="(article, index) in category.articles">
          <v-col v-if="index < 9" :key="index" cols="12" md="6" lg="4">
            <edit-asset-button
              :link="`/articles/edit/${article.slug}`"
            ></edit-asset-button>
            <nuxt-link :to="`/articles/${article.slug}`">
              <v-hover v-slot="{ hover }">
                <v-card flat height="100%" tile>
                  <v-img
                    :alt="article.title"
                    :src="article.cover_image"
                    aspect-ratio="1.5"
                  >
                  </v-img>
                  <v-card-text>
                    <p
                      class="title transition-animations"
                      :class="hover ? 'primary--text' : ''"
                    >
                      {{ article.title }}
                    </p>
                    <span
                      v-if="article.display_date"
                      class="
                        text--secondary
                        font-weight-normal
                        caption
                        text-capitalize
                      "
                    >
                      {{ article.display_date | formatDate }}
                    </span>
                    <div
                      class="caption text--primary"
                      v-text="article.short_description"
                    ></div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </nuxt-link>
          </v-col>
        </template>
      </v-row>
    </div>
  </LazyHydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import EditAssetButton from '~/components/common/EditAssetButton'

export default {
  name: 'CategoryLayout3',
  components: {
    EditAssetButton,
    LazyHydrate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    articles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      category: {
        ...this.data,
      },
    }
  },
}
</script>
