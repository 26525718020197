var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_vm._l((_vm.categories),function(category,i){return [(category.articles && category.articles.length)?_c('div',{key:category.id,staticClass:"category-title mb-2 d-flex align-center justify-space-between",class:{ 'mt-5': i > 0 },style:({
        'border-color': category.color
          ? category.color + '!important'
          : 'var(--v-primary-base)',
      })},[_c('h1',{staticClass:"title d-inline-block py-2 px-5 primary white--text",style:({
          'background-color': category.color
            ? category.color + '!important'
            : 'var(--v-primary-base)',
        })},[_vm._v("\n        "+_vm._s(category.name)+"\n      ")]),_vm._v(" "),(!category.hide_details)?_c('v-btn',{attrs:{"text":"","to":_vm.localePath(("/categories/" + (category.id) + "/view/" + (category.slug)))}},[_vm._v("Vezi mai multe")]):_vm._e(),_vm._v(" "),_c('edit-asset-button',{attrs:{"link":("/categories/edit/" + (category.slug))}})],1):_vm._e(),_vm._v(" "),(category.articles && category.articles.length)?_c('v-row',{key:category.name},[_c('v-col',[_c(_vm.getComponentName(category),{tag:"component",attrs:{"data":category}})],1)],1):_vm._e()]}),_vm._v(" "),_vm._l((_vm.pageWidgets),function(widget){return _c(_vm.getWidgetComponentName(widget.type),{key:widget.id,tag:"component",staticClass:"mt-5",attrs:{"data":widget,"with-edit":""}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }