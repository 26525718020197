<template>
  <LazyHydrate when-visible>
    <v-row v-if="category.articles && category.articles.length" dense>
      <template v-for="(article, index) in category.articles">
        <v-col v-if="index < 2" :key="index" cols="12" md="6">
          <edit-asset-button
            :link="`/articles/edit/${article.slug}`"
          ></edit-asset-button>
          <nuxt-link :to="`/articles/${article.slug}`">
            <v-hover v-slot="{ hover }">
              <v-card flat height="100%" tile>
                <v-img
                  :alt="article.title"
                  :src="article.cover_image"
                  aspect-ratio="1.5"
                >
                </v-img>
                <v-card-text>
                  <p
                    class="title transition-animations"
                    :class="hover ? 'primary--text' : ''"
                  >
                    {{ article.title }}
                  </p>
                  <span
                    v-if="article.display_date"
                    class="
                      text--secondary
                      font-weight-normal
                      caption
                      text-capitalize
                    "
                  >
                    {{ article.display_date | formatDate }}
                  </span>
                  <div
                    class="caption text--primary"
                    v-text="article.short_description"
                  ></div>
                </v-card-text>
              </v-card>
            </v-hover>
          </nuxt-link>
        </v-col>
        <v-col v-if="index > 1 && index < 9" :key="index" cols="12" lg="6">
          <edit-asset-button
            :link="`/articles/edit/${article.slug}`"
          ></edit-asset-button>
          <nuxt-link :to="`/articles/${article.slug}`">
            <v-hover v-slot="{ hover }">
              <v-card flat tile>
                <v-layout>
                  <v-flex>
                    <v-img
                      :alt="article.title"
                      :src="article.cover_image"
                      width="150px"
                      aspect-ratio="1.5"
                    >
                    </v-img>
                  </v-flex>
                  <v-card-text
                    class="py-1 d-flex flex-column justify-space-between"
                  >
                    <span
                      class="subtitle-2 transition-animations"
                      :class="hover ? 'primary--text' : ''"
                    >
                      {{ article.title.substr(0, 100) }} {{article.title.length > 100 ? '...' : ''}}
                    </span>
                    <span
                      v-if="article.display_date"
                      class="
                        text--secondary
                        font-weight-normal
                        caption
                        text-capitalize
                      "
                    >
                      {{ article.display_date | formatDate }}
                    </span>
                  </v-card-text>
                </v-layout>
              </v-card>
            </v-hover>
          </nuxt-link>
        </v-col>
      </template>
    </v-row>
  </LazyHydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import EditAssetButton from '~/components/common/EditAssetButton'

export default {
  name: 'CategoryLayout2',
  components: {
    EditAssetButton,
    LazyHydrate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    articles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      category: {
        ...this.data,
      },
    }
  },
}
</script>
