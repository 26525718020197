<template>
  <LazyHydrate when-visible>
    <v-row v-if="category.articles && category.articles.length" dense>
      <v-col cols="12" md="6">
        <edit-asset-button
          :link="`/articles/edit/${category.articles[0].slug}`"
        ></edit-asset-button>
        <nuxt-link :to="`/articles/${category.articles[0].slug}`">
          <v-hover v-slot="{ hover }">
            <v-card flat tile>
              <v-img
                :alt="category.articles[0].title"
                :src="category.articles[0].cover_image"
                aspect-ratio="1.5"
              >
              </v-img>
              <v-card-text>
                <p
                  class="title transition-animations"
                  :class="hover ? 'primary--text' : ''"
                >
                  {{ category.articles[0].title }}
                </p>
                <span
                  v-if="category.articles[0].display_date"
                  class="
                    text--secondary
                    font-weight-normal
                    caption
                    text-capitalize
                  "
                >
                  {{ category.articles[0].display_date | formatDate }}
                </span>
                <div
                  class="caption text--primary"
                  v-text="category.articles[0].short_description"
                ></div>
              </v-card-text>
            </v-card>
          </v-hover>
        </nuxt-link>
      </v-col>
      <v-col cols="12" md="6">
        <template v-for="(article, index) in category.articles">
          <v-row
            v-if="index > 0 && index < 6"
            :key="index"
            no-gutters
            class="mb-2"
          >
            <v-col cols="12">
              <edit-asset-button
                :link="`/articles/edit/${article.slug}`"
              ></edit-asset-button>
              <nuxt-link :to="`/articles/${article.slug}`">
                <v-hover v-slot="{ hover }">
                  <v-card flat tile>
                    <v-layout>
                      <v-flex>
                        <v-img
                          :src="article.cover_image"
                          :alt="article.title"
                          width="150px"
                          aspect-ratio="1.5"
                        >
                        </v-img>
                      </v-flex>
                      <v-card-text
                        class="py-0 d-flex flex-column justify-space-between"
                      >
                        <span
                          class="subtitle-2 transition-animations"
                          :class="hover ? 'primary--text' : ''"
                        >
                          {{ article.title }}
                        </span>
                        <span
                          v-if="article.display_date"
                          class="
                            text--secondary
                            font-weight-normal
                            caption
                            text-capitalize
                          "
                        >
                          {{ article.display_date | formatDate }}
                        </span>
                      </v-card-text>
                    </v-layout>
                  </v-card>
                </v-hover>
              </nuxt-link>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </LazyHydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import EditAssetButton from '~/components/common/EditAssetButton'

export default {
  name: 'CategoryLayout1',
  components: {
    EditAssetButton,
    LazyHydrate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    articles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      category: {
        ...this.data,
      },
    }
  },
}
</script>
